
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { waitOneFrame, waitSeconds } from '~/assets/ts/utils/misc'

export default Vue.extend({
  name: 'CopyText',
  components: { SaIcon },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
      fade: false,
    }
  },
  methods: {
    async copyText() {
      if (this.copied) return
      try {
        await window.navigator.clipboard.writeText(this.text)
      } catch (e) {
        console.error(e)
        this.$emit('failed')
        return
      }
      this.copied = true
      this.$emit('copied')
      this.$emit('click')
      await waitOneFrame()
      this.fade = false
      await waitSeconds(0.75)
      this.copied = false
      this.fade = true
    },
  },
})
