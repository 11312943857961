
import Vue from 'vue'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import QR from '~/components/_general/QR.vue'

export default Vue.extend({
  name: 'ShareQR',
  components: { QR, SaIcon, CustomDropdown },
  props: {
    url: {
      type: String,
      default: undefined,
    },
    size: {
      type: Number,
      default: 132,
    },
    transparentBg: {
      type: Boolean,
      default: true,
    },
    white: {
      type: Boolean,
      default: true,
    },
    dropUp: {
      type: Boolean,
      default: true,
    },
    /** Tailwind bg color or theme color */
    dropBgClass: {
      type: String,
      default: 'bg-transparent',
      validator: (value: string) => {
        return value === '' || value.includes('bg-') || value === 'bg-theme'
      },
    },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    opened() {
      this.$emit('opened')
      this.open = true
    },
    closed() {
      this.$emit('closed')
      this.open = false
    },
  },
})
