
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { CurrentAbsoluteUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'ShareEmail',
  components: { SaIcon },
  props: {
    url: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    fullUrl(): string {
      return this.url || CurrentAbsoluteUrl(this)
    },
  },
})
