
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import { Broadcaster } from '~/models/broadcaster'
import SaIcon from '~/components/_general/SaIcon.vue'

export const EmbedButtonProps = {
  sermon: {
    type: Object as PropType<Sermon>,
    default: undefined,
  },
  broadcaster: {
    type: Object as PropType<Broadcaster>,
    default: undefined,
  },
  view: {
    type: String as PropType<'audio' | 'video' | 'browser' | 'webcast'>,
    default: 'browser',
  },
}

export default Vue.extend({
  name: 'EmbedButton',
  components: { SaIcon },
  props: {
    ...EmbedButtonProps,
  },
  computed: {
    url(): string {
      const base = 'https://embed.sermonaudio.com/embed-editor'
      const qs = `?pop=true&view=${this.view}`
      if (this.sermon) {
        return `${base}/sermon/${this.sermon.id}/${qs}`
      } else if (this.broadcaster) {
        return `${base}/broadcaster/${this.broadcaster.id}/${qs}`
      } else {
        return ''
      }
    },
  },
})
