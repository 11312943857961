
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { CurrentAbsoluteUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'ShareTwitter',
  components: { SaIcon },
  props: {
    url: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    fullUrl(): string {
      return this.url || CurrentAbsoluteUrl(this)
    },
    twitterUrl(): string {
      const url = this.fullUrl
      return `https://twitter.com/intent/tweet?original_referer=${url}&ref_src=twsrc%5Etfw&text=${this.title}&tw_p=tweetbutton&url=${url}`
    },
  },
  methods: {
    openTwitter(e: Event) {
      e.preventDefault()
      window.open(
        this.twitterUrl,
        'twitter',
        'width=600, height=600,left=0,top=0'
      )
    },
  },
})
