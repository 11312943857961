
import Vue from 'vue'
import CopyText from '~/components/_general/CopyText.vue'
import { CurrentAbsoluteUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'ShareLink',
  components: { CopyText },
  props: {
    url: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    fullUrl(): string {
      return this.url || CurrentAbsoluteUrl(this)
    },
    shareUrl(): string {
      return this.fullUrl.replace('https://', '').replace('http://', '')
    },
  },
})
