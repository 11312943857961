
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { CurrentAbsoluteUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'ShareFacebook',
  components: { SaIcon },
  props: {
    url: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    fullUrl(): string {
      return this.url || CurrentAbsoluteUrl(this)
    },
    facebookUrl(): string {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.fullUrl}`
    },
  },
  methods: {
    openFacebook(e: Event) {
      e.preventDefault()
      window.open(
        this.facebookUrl,
        'facebook',
        'width=600, height=600,left=0,top=0'
      )
    },
  },
})
