
import Vue from 'vue'
// https://www.npmjs.com/package/qrcode.vue
import QrcodeVue from 'qrcode.vue'
import { CurrentAbsoluteUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  components: { QrcodeVue },
  props: {
    url: {
      type: String,
      default: undefined,
    },
    size: {
      type: Number,
      default: 132,
    },
    transparentBg: {
      type: Boolean,
      default: true,
    },
    white: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    bgColor(): string {
      const base = this.white ? '#000000' : '#ffffff'
      return `${base}${this.transparentBg ? '00' : 'ff'}`
    },
    qrColor(): string {
      return this.white ? '#ffffffff' : '#000000ff'
    },
    fullUrl(): string {
      return this.url || CurrentAbsoluteUrl(this)
    },
  },
})
